import React, { useMemo, useCallback, useContext } from "react";
import { Typeahead, Menu, MenuItem, Input } from "react-bootstrap-typeahead";
import { injectIntl } from "react-intl";

import GridTableDisplay from "../Base/Shared/GridTableDisplay";
import { getOfficialRoles } from "../../Config/OfficialsRoles";
import { FixtureDetailsContext } from "../../Config/FixtureDetails";

import "react-bootstrap-typeahead/css/Typeahead.scss";
import "./Officials.scss";

const EMPTY_OFFICIAL_ENTRY = {
  role: null,
  personId: null,
};

const Officials = ({ availableOfficials, officialsData, updateOfficialsData, intl: { formatMessage } }) => {
  const { sport } = useContext(FixtureDetailsContext);
  const columns = useMemo(() => {
    return [
      formatMessage({
        id: "person.role",
        defaultMessage: "Role",
      }),
      formatMessage({
        id: "person.name",
        defaultMessage: "Name",
      }),
      "",
    ];
  }, [formatMessage]);

  const officialsRoles = useMemo(
    () =>
      Object.values(getOfficialRoles(sport)).map((entry) => (
        <option key={entry.role} value={entry.role}>
          {formatMessage({
            id: `role.${entry.role.toLowerCase()}`,
            defaultMessage: `${entry.role}`,
          })}
        </option>
      )),
    [formatMessage, sport],
  );

  function handleAddOfficials() {
    updateOfficialsData(EMPTY_OFFICIAL_ENTRY, -1);
  }

  const handleOfficialRemoved = useCallback(
    (officialIndex) => {
      updateOfficialsData(null, officialIndex);
    },
    [updateOfficialsData],
  );

  const handleOfficialRoleChanged = useCallback(
    (e, officialIndex) => {
      const newOfficialPersonData = {
        ...officialsData[officialIndex],
        role: e.target.value,
      };
      if (e.target.value === "") {
        newOfficialPersonData.personId = null;
        newOfficialPersonData.role = null;
      }
      updateOfficialsData(newOfficialPersonData, officialIndex);
    },
    [officialsData, updateOfficialsData],
  );

  const handleOfficialPersonChange = useCallback(
    (selected, officialIndex) => {
      const newOfficialPersonData = {
        ...officialsData[officialIndex],
      };
      // this means a person was unassigned from a official role
      if (selected.length === 0) {
        newOfficialPersonData.personId = null;
      } else {
        newOfficialPersonData.personId = selected[0].personId;
      }
      updateOfficialsData(newOfficialPersonData, officialIndex);
    },
    [officialsData, updateOfficialsData],
  );

  const rows = useMemo(() => {
    return officialsData.map((official, index) => {
      const isPersonSelected = official?.personId !== null;
      return [
        <select value={official.role || ""} onChange={(e) => handleOfficialRoleChanged(e, index)}>
          <option value=""></option>
          {officialsRoles}
        </select>,
        <Typeahead
          id={`official-typeahead-${index}`}
          clearButton={isPersonSelected}
          options={availableOfficials}
          labelKey={(option) => option.name || ""}
          onChange={(selected) => handleOfficialPersonChange(selected, index)}
          selected={availableOfficials.filter((o) => o.personId === official.personId)}
          renderMenu={(results, menuProps) => (
            <Menu className="officials--person-menu" id={menuProps.id}>
              {results.map((result, index) => (
                <MenuItem
                  option={result}
                  position={index}
                  className="officials--person-menu-item"
                  key={`officials-person-role-${index}`}
                >
                  {result.name || ""}
                </MenuItem>
              ))}
            </Menu>
          )}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <Input
              {...inputProps}
              className={`officials--person-input officials--person-input-isEmpty-${!isPersonSelected}`}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
            />
          )}
        />,
        <span className="officials--remove-btn">
          {official.required ? null : <i className="fas fa-times" onClick={() => handleOfficialRemoved(index)}></i>}
        </span>,
      ];
    });
  }, [
    officialsData,
    availableOfficials,
    handleOfficialPersonChange,
    handleOfficialRemoved,
    handleOfficialRoleChanged,
    officialsRoles,
  ]);

  return (
    <div className="game-details--game-officials">
      <div className="game-details--section-header-no-border">
        {formatMessage({
          id: "details.officials",
          defaultMessage: "Officials",
        })}
      </div>
      <div className="officials--container">
        <GridTableDisplay
          columns={columns}
          rows={rows}
          headerClass="officials--table-header"
          rowCellClass="officials-cell"
          tableKeyPrefix="officials-roster"
        />
        <div className="officials--add-button" onClick={handleAddOfficials}>
          <i className="fas fa-plus"></i>{" "}
          {formatMessage({
            id: "details.officials.addOfficials",
            defaultMessage: "Add Officials",
          })}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Officials);
