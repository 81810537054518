import { set } from "idb-keyval";
import Paho from "paho-mqtt";
import { v1 as uuidv1 } from "uuid";

import { Tracing } from "../../../App.tracing";
import defaultSetup from "../../../Config/defaultSetupConfiguration";
import { configureStore, getEventTime } from "./SendEvent";
import SequenceIdGenerator from "./SequenceIdGenerator";

const idGenerator = SequenceIdGenerator.getInstance();

export function sendClockEvent(
  eventClass,
  type,
  subType,
  props,
  option = null,
  value = null
) {
  const { currentState } = props;
  const fixtureId = props.currentState.fixtureId;

  const eventStore = configureStore(props, "event");

  let msg = {};
  msg.class = eventClass;
  msg.eventType = type;

  if (subType) {
    msg.subType = subType;
  }

  if (option && subType !== "adjust") {
    msg.options = {
      option: value,
    };
  } else if (option && subType === "adjust") {
    msg.options = {
      value: value,
    };
  }

  if (msg.class === "clock") {
    let clockParts = props.currentState.clock.displayTime.split(":");
    msg.clock =
      "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
  }

  msg.eventId = uuidv1();
  msg.status = "added";
  msg.periodId = currentState.period ? currentState.period.periodId : 1;

  let timestamp = new Date();
  msg.timestamp = timestamp.toISOString();
  msg.eventTime = getEventTime();
  msg.sequence = idGenerator.getId();

  let messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });

  if (!currentState.processing) {
    var message = new Paho.Message(messageContent);
    if (currentState.mqtt) {
      message.destinationName = currentState.mqtt.topic;
      if (currentState.connected && currentState.mqttConnected) {
        try {
          currentState.mqtt.client.send(message);
        } catch (err) {
          Tracing.capture(err);
          console.error("Send Error", err);
        }
      }
      msg.pending = true;
      props.manageEvents(msg.eventId, msg);
      set(msg.eventId, msg, eventStore);
    }
  }
}
