const createSuspensionClockEvent = () => [
  { action: "newEvent", value: null, type: "main" },
  { action: "saveData", value: "clock", type: "class" },
  { action: "saveData", value: "suspension", type: "eventType" },
  { action: "saveData", value: "adjust", type: "subType" },
  { action: "saveData", value: [{ option: "value", value: "2" }], type: "options" },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "suspension", type: "from" },
];

const startSuspensionClockEvent = () => [
  { action: "copyEvent", value: "suspension", type: "to" },
  { action: "copyEvent", value: "card-clock", type: "from" },
  { action: "copyData", value: "personId", type: "suspension" },
  { action: "sendEvent", value: "edit", type: "main" },
  { action: "newEventId", value: null, type: "main" },
  { action: "saveData", value: "start", type: "subType" },
  { action: "clearData", value: ["options"], type: "main"},
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "suspension", type: "from" },
];

const redCardActionGroup = [
  { action: "newEvent", value: null, type: "main" },
  { action: "saveData", value: "redCard", type: "eventType" },
  { action: "sendEvent", value: "new", type: "main" },
];

export const SuspensionPlayerSelect = {
  name: "suspension-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "suspension.select-player",
  showOn: [{ state: "primary", value: "suspension-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  allowBack: {
    state: "primary",
    value: "suspension-type",
    event: "main",
    save: false,
  },
  children: [
    {
      name: "suspension-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: true,
        showCoach: true,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "coach", value: true }],
              type: "options",
            },
          ],
          conditions: [
            {
              item: "coach",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "bench", value: true }],
              type: "options",
            },
          ],
          conditions: [
            {
              item: "bench",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "copyData", type: "suspension", value: "eventType" },
            { action: "copyData", type: "suspension", value: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "clearData", value: ["options"], type: "main" },
          ],
          conditions: [],
        },
        {
          actions: redCardActionGroup,
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "suspension",
              count: 2,
              event: "main",
            },
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "fourMinutes",
              event: "main",
              operator: "is",
            },
            {
              item: "findInStore",
              field: "subType",
              value: "twoMinutes",
            },
          ],
        },
        {
          actions: redCardActionGroup,
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "suspension",
              count: 2,
              event: "main",
            },
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "twoMinutes",
              event: "main",
              operator: "is",
            },
            {
              item: "findInStore",
              field: "subType",
              value: "fourMinutes",
            },
          ],
        },
        {
          actions: redCardActionGroup,
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "suspension",
              count: 3,
              event: "main",
            },
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...startSuspensionClockEvent(),
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "confirm-later-suspension-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: true, type: "flagged" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "clearData", value: ["options"], type: "main" },
            ...createSuspensionClockEvent(),
            ...startSuspensionClockEvent(),
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
