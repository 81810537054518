const PenaltyShotOutcomeTypes = ["goal", "miss", "retaken"];

const copyFromShotActions = [
  { action: "saveData", value: "goal", type: "eventType" },
  { action: "copyData", type: "shot", value: "entityId" },
  { action: "copyData", type: "shot", value: "personId" },
  { action: "copyData", type: "shot", value: "subType" },
  { action: "copyData", type: "shot", value: "options" },
];

export const PenaltyShotOutcomeSelect = {
  name: "penalty-shot-outcome",
  component: "MainPanel",
  layout: "main-panel large no-group",
  mode: "visible",
  text: "penalty.shot-outcome",
  showOn: [{ state: "primary", value: "penalty-shot-outcome", operator: "is" }],
  startEvent: true,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  allowCancelPlay: true,
  children: [
    {
      name: "shot-outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "outcome",
        children: PenaltyShotOutcomeTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: [{ option: "outcome" }], type: "main" },
            { action: "newEvent", value: null, type: "main" },
            ...copyFromShotActions,
            { action: "saveData", value: true, type: "success" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "outcome",
              value: "GOAL",
            },
          ],
        },
        {
          actions: [
            { action: "clearData", value: [{ option: "outcome" }], type: "main" },
            { action: "newEvent", value: null, type: "main" },
            ...copyFromShotActions,
            { action: "saveData", value: false, type: "success" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            {
              action: "nextState",
              value: "penalty-shot-failure",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "outcome",
              value: "MISS",
            },
          ],
        },
        {
          actions: [
            {
              action: "forceNextState",
              value: "penalty-shot-outcome",
              delayInMiliseconds: 500,
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "outcome",
              value: "RETAKEN",
            },
          ],
        },
      ],
    },
  ],
};
