import React, { useEffect, useState, useCallback, useContext } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import ClockButtons from "./ClockButtons/ClockButtons";
import ClockAdjust from "./ClockAdjust/ClockAdjust";

import { useInterval } from "../../Base/Hooks/Interval";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import { usePrevious } from "../../Base/Hooks/Previous";
import { useIsTabActive } from "../../Base/Hooks/isTabActive";
import { sendClockEvent } from "../../Base/Utilities/ClockEvents";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import "./Clock.scss";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent.js";
import { sendEvent } from "../../Base/Utilities/SendEvent.js";
import {
  convertDisplayTimeToDataTime
} from "../../Base/Utilities/ConvertClock.js";

const Clock = (props) => {
  const { currentState, updateState, panel, eventStore, managePlays, manageEvents } = props;
  const { clock } = currentState;
  const [totalSeconds, setTotalSeconds] = useState();
  const [initialSeconds, setInitialSeconds] = useState();
  const [displayTime, setDisplayTime] = useState();
  const [clockRunning, setClockRunning] = useState(false);
  const [clockEvent, setClockEvent] = useState(null);
  const [clockDelay, setClockDelay] = useState(1000000);
  const [currentKey, setCurrentKey] = useState(null);
  const [enableButtons, setEnableButtons] = useState(true);
  const [editClock, setEditClock] = useState(false);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [isTabActive, tabLastActive] = useIsTabActive();
  const [inactivityTimeStart, setInactivityTimeStart] = useState(null);

  const prevClock = usePrevious(props.currentState.clock);
  const prevPeriod = usePrevious(props.currentState.period);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  useInterval(() => {
    _isClockRunning();
  }, clockDelay);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone, props]);

  useEffect(() => {
    let seconds =
      currentState?.period?.periodId >= fixtureProfile.initialExtraPeriodId
        ? fixtureProfile.overtimeLength * 60
        : fixtureProfile.periodLength * 60;
    setInitialSeconds(seconds);
    if (fixtureProfile.clockDirection === "COUNT_DOWN") {
      setTotalSeconds(seconds);
    } else {
      setTotalSeconds(0);
    }
    // _setClockRunning(false, null);
    window.addEventListener("keydown", _handleKeyDown);
    return () => window.removeEventListener("keydown", _handleKeyDown);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Stop Clock and send message if stopped outside of here
    if (clock && clock.clockStatus === null && prevClock && prevClock.clockStatus === "auto") {
      if (clock && clock.clockRunning === false) {
        _setClockRunning(false, null);
        sendClockEvent("clock", "main", "stop", props);
      }
      if (clock && clock.clockRunning === true) {
        _setClockRunning(true, null);
        sendClockEvent("clock", "main", "start", props);
      }
    }
    // eslint-disable-next-line
  }, [clock]);

  useEffect(() => {
    if (currentState.mqtt && currentState.mqtt.topic && currentState.period) {
      switch (currentState.period.periodStatus) {
        case "initial":
          // hockey needs to support warm_up phase. Please see "WarmupClock" component.
          if (sport === "hockey") {
            return;
          }

          if (currentState.period.periodId === 1) {
            sendClockEvent("sport", "fixture", "pending", props);
          }
          sendClockEvent("sport", "period", "pending", props);
          updateState("period", {
            periodId: currentState.period.periodId,
            periodStatus: "pending",
          });
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [currentState.period]);

  useEffect(() => {
    if (currentState.clock && currentState.clock.clockRunning !== clockRunning) {
      _setClockRunning(currentState.clock.clockRunning, null);
    }
    if (currentState?.clock?.displayTime && currentState.clock.displayTime !== displayTime) {
      let clockParts = currentState.clock.displayTime.split(":");
      let seconds = parseInt(clockParts[0]) * 60 + parseInt(clockParts[1]);
      setTotalSeconds(seconds);
    }
    if (
      panel.options.disablePreMatch &&
      currentState.period &&
      currentState.period.periodStatus === "pending" &&
      (panel.options.disableOnPeriodStart ||
        (!panel.options.disableOnPeriodStart && currentState.period.periodId === 1))
    ) {
      setEnableButtons(false);
    } else {
      if (!enableButtons) {
        setEnableButtons(true);
      }
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    let min = Math.floor(totalSeconds / 60);
    let sec = totalSeconds - min * 60;

    // guard & break if NaN happen
    // TODO: debug this issue, they shouldn't ever be NaN
    if (Number.isNaN(min) || Number.isNaN(sec)) {
      return;
    }

    let timeDisplay = _buildDisplayTime(min, sec);
    setDisplayTime(timeDisplay);
    updateState("clock", {
      displayTime: timeDisplay,
      clockRunning: clockRunning,
      clockStatus: clockEvent,
    });
    if (
      totalSeconds > 0 &&
      totalSeconds < initialSeconds &&
      currentState.period.periodStatus !== "pending" &&
      currentState.period.periodStatus !== "fixtureComplete"
    ) {
      updateState("period", {
        periodId: currentState.period.periodId,
        periodStatus: "inProgress",
      });
    }
    if (clockRunning) {
      if (fixtureProfile.clockDirection === "COUNT_DOWN") {
        if (totalSeconds < 1) {
          _setClockRunning(false, "clockStop");
          setTotalSeconds(0);
        }
      } else {
        if (totalSeconds > initialSeconds) {
          _setClockRunning(false, "clockStop");
          setTotalSeconds(initialSeconds);
        }
      }
      setClockDelay(1000);
    } else {
      if (fixtureProfile.clockDirection === "COUNT_DOWN") {
        if (totalSeconds < 1 && currentState.period.periodStatus !== "pending") {
          if (
            !["periodEnd", "fixtureConfirm", "pendingExtra", "fixtureComplete"].includes(
              currentState.period.periodStatus,
            )
          ) {
            setTotalSeconds(0);
            updateState("period", {
              periodId: currentState.period.periodId,
              periodStatus: "ended",
            });
          }
        }
      } else {
        if (
          totalSeconds > initialSeconds - 1 &&
          currentState.period.periodStatus !== "pending" &&
          currentState.period.periodStatus !== "fixtureComplete"
        ) {
          setTotalSeconds(initialSeconds);
          updateState("period", {
            periodId: currentState.period.periodId,
            periodStatus: "ended",
          });
        }
      }
      setClockDelay(1000000);
    }
    // eslint-disable-next-line
  }, [totalSeconds, clockRunning]);

  useEffect(() => {
    // Send clock start / stop messages based on clockRunning state
    if (props.currentState.mqtt) {
      // let periodDetails = props.currentState.period;
      let clockDetails = props.currentState.clock;
      if (prevClock && prevClock.clockRunning !== clockDetails.clockRunning) {
        if (clockDetails.clockRunning) {
          sendClockEvent("clock", "main", "start", props);
        } else {
          sendClockEvent("clock", "main", "stop", props);
        }
      }
      if (prevPeriod && prevClock && clockDetails.clockRunning !== prevClock.clockRunning) {
      }
    }
    // eslint-disable-next-line
  }, [clockRunning, props]);

  useEffect(() => {
    if (
      currentKey !== null &&
      currentState &&
      currentState.period &&
      (currentState.period.periodStatus === "inProgress" || currentState.period.periodStatus === "ended")
    ) {
      switch (currentKey) {
        case "Space":
          if (!panel?.options?.disableControls) {
            if (currentState.period.periodStatus === "inProgress") {
              let clockAction = clockRunning === true ? "clockStop" : "clockStart";
              _setClockRunning(!clockRunning, clockAction);
            }
          }
          break;
        case "ArrowUp":
          if (!clockRunning && totalSeconds < initialSeconds) {
            setTotalSeconds(totalSeconds + 1);
            _sendClockAdjustment(totalSeconds + 1);
          }
          break;
        case "ArrowDown":
          if (!clockRunning && totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
            _sendClockAdjustment(totalSeconds - 1);
          }
          break;
        case "ArrowRight":
          if (!clockRunning && totalSeconds < initialSeconds - 59) {
            setTotalSeconds(totalSeconds + 60);
            _sendClockAdjustment(totalSeconds + 60);
          }
          break;
        case "ArrowLeft":
          if (!clockRunning && totalSeconds > 59) {
            setTotalSeconds(totalSeconds - 60);
            _sendClockAdjustment(totalSeconds - 60);
          }
          break;
        case "KeyC":
          updateState("region", null);
          break;
        default:
          break;
      }
    }
    setCurrentKey(null);
    // eslint-disable-next-line
  }, [currentKey]);

  useEffect(() => {
    if (displayTime && clockEvent) {
      updateState("clock", {
        displayTime: currentState.clock.displayTime,
        clockRunning: clockRunning,
        clockStatus: clockEvent,
      });
      setDoAction(true);
    }
    // eslint-disable-next-line
  }, [clockEvent]);

  const _handleKeyDown = useCallback((event) => {
    setCurrentKey(event.code);
  }, []);

  const _isClockRunning = useCallback(() => {
    if (clockRunning) {
      if (fixtureProfile.clockDirection === "COUNT_DOWN") {
        setTotalSeconds(totalSeconds - 1);
      } else {
        setTotalSeconds(totalSeconds + 1);
      }
    }
  }, [clockRunning, totalSeconds, fixtureProfile]);

  useEffect(() => {
    if (clockRunning) {
      if (isTabActive) {
        if (inactivityTimeStart) {
          const dt = Date.now() - tabLastActive;
          let seconds = 0;
          if (fixtureProfile.clockDirection === "COUNT_DOWN") {
            seconds = inactivityTimeStart - Math.floor(dt / 1000);
          } else {
            seconds = inactivityTimeStart + Math.floor(dt / 1000);
          }
          setTotalSeconds(Math.min(Math.max(seconds, 0), initialSeconds));
        }
      } else {
        setInactivityTimeStart(totalSeconds);
      }
    }
    // eslint-disable-next-line
  }, [isTabActive]);

  const _setClockRunning = (value, status) => {
    let periodDetails = currentState.period;
    if (periodDetails) {
      setClockEvent(status);
      switch (periodDetails.periodStatus) {
        case "pending":
        case "warm_up":
          let newPeriodLength =
            periodDetails.periodId >= fixtureProfile.initialExtraPeriodId
              ? fixtureProfile.overtimeLength * 60
              : initialSeconds;
          if (
            (totalSeconds === 0 && fixtureProfile.clockDirection === "COUNT_UP") ||
            (totalSeconds === newPeriodLength && fixtureProfile.clockDirection === "COUNT_DOWN")
          ) {
            updateState("period", {
              periodId: periodDetails.periodId,
              periodStatus: "inProgress",
            });
          }
          setClockRunning(false);
          if (status === "periodStart" && !clockRunning) {
            setClockRunning(true);
            sendClockEvent("sport", "period", "start", props);
          }

          if (status === "fixtureStart") {
            if (currentState.period.periodId === 1) {
              sendClockEvent("sport", "fixture", "start", props);
            }
            sendClockEvent("sport", "period", "start", props);
            setClockRunning(true);
          }
          break;
        case "inProgress":
          if (
            (totalSeconds === 0 && fixtureProfile.clockDirection === "COUNT_DOWN") ||
            (totalSeconds > initialSeconds - 1 && fixtureProfile.clockDirection === "COUNT_UP")
          ) {
            if (!currentState.processing) {
              updateState("period", {
                periodId: periodDetails.periodId,
                periodStatus: "ended",
              });
            }
          }
          if (status === "fixtureEnd") {
            sendClockEvent("sport", "fixture", "end", props);
            updateState("period", {
              periodId: periodDetails.periodId,
              periodStatus: "fixtureConfirm",
            });
          }

          if (sport === "handball") {
            const lastVideoReviewEvent = eventStore.findLast(event => event.class === "sport" && event.eventType === "videoReview");
            if (lastVideoReviewEvent?.subType === "start" && status === "clockStart") {
              const action = createBaseEvent(currentState);
              action.event.eventType = "videoReview";
              action.event.subType = "end";
              action.event.clock = convertDisplayTimeToDataTime(currentState.clock.displayTime);

              sendEvent(action, props);
            }
          }

          setClockRunning(value);
          break;
        case "ended":
          // dirty! send start & stop to make sure BE has right time
          sendClockEvent("clock", "main", "start", props);
          sendClockEvent("clock", "main", "stop", props);
          sendClockEvent("sport", "period", "end", props);
          updateState("period", {
            periodId: periodDetails.periodId,
            periodStatus: "periodEnd",
          });
          break;
        case "periodEnd":
          sendClockEvent("sport", "period", "confirmed", props);
          sendClockEvent("internal", "betStop", null, props);

          let newPeriod = 0;
          if (periodDetails.periodId >= fixtureProfile.numberOfPeriods) {
            updateState("period", {
              periodId: periodDetails.periodId,
              periodStatus: "pendingExtra",
            });
          } else {
            newPeriod = periodDetails.periodId + 1;
            updateState("period", {
              periodId: newPeriod,
              periodStatus: "pending",
            });
            sendClockEvent("sport", "period", "pending", props);
            setDoAction(true);
            let newPeriodLength =
              newPeriod >= fixtureProfile.initialExtraPeriodId ? fixtureProfile.overtimeLength * 60 : initialSeconds;
            setInitialSeconds(newPeriodLength);
            if (fixtureProfile.clockDirection === "COUNT_DOWN") {
              setTotalSeconds(newPeriodLength);
            } else {
              setTotalSeconds(0);
            }

            if (status === "confirmed" && sport === "hockey") {
              updateState("posTracking", null);
            }
          }
          break;
        case "pendingExtra":
          switch (status) {
            case "fixtureEnd":
              sendClockEvent("sport", "fixture", "end", props);
              updateState("period", {
                periodId: periodDetails.periodId,
                periodStatus: "fixtureConfirm",
              });

              break;
            case "startExtra":
              let newPeriod = 0;
              if (periodDetails.periodId === fixtureProfile.numberOfPeriods) {
                newPeriod = fixtureProfile.initialExtraPeriodId;
              } else {
                newPeriod = periodDetails.periodId + 1;
              }
              updateState("period", {
                periodId: newPeriod,
                periodStatus: "pending",
              });

              sendClockEvent("sport", "period", "pending", props);

              let newPeriodLength =
                newPeriod >= fixtureProfile.initialExtraPeriodId ? fixtureProfile.overtimeLength * 60 : initialSeconds;
              setInitialSeconds(newPeriodLength);
              if (fixtureProfile.clockDirection === "COUNT_DOWN") {
                setTotalSeconds(newPeriodLength);
              } else {
                setTotalSeconds(0);
              }

              break;
            case "startShootout":
              sendClockEvent("sport", "period", "pending", props);
              sendClockEvent("sport", "period", "start", props);
              break;
            default:
              break;
          }

          break;
        case "fixtureConfirm":
          sendClockEvent("sport", "fixture", "confirmed", props);
          updateState("period", {
            periodId: periodDetails.periodId,
            periodStatus: "fixtureComplete",
          });

          break;

        default:
          break;
      }
    }
  };

  function _buildDisplayTime(mins, secs) {
    let Time = [mins, secs];
    if (secs < 10) {
      Time[1] = "0" + secs;
    }
    if (mins < 10) {
      Time[0] = "0" + mins;
    }
    return Time[0] + ":" + Time[1];
  }

  function _sendClockAdjustment(seconds) {
    let min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;

    let newTime = ("PT" + _buildDisplayTime(min, sec) + "S").replace(":", "M");
    sendClockEvent("clock", "main", "adjust", props, "value", newTime);
  }

  const renderPeriodText = () => {
    if (currentState?.period?.periodStatus) {
      const { periodId } = currentState.period;
      if (periodId >= fixtureProfile.initialExtraPeriodId) {
        if (
          !["hockey", "handball"].includes(sport) ||
          (["handball"].includes(sport) && periodId !== fixtureProfile.shootOutPeriodId)
        ) {
          return (
            <FormattedMessage
              id="period.overtime.full"
              defaultMessage="Overtime {period}"
              values={{
                period: currentState.period.periodId - (fixtureProfile.initialExtraPeriodId - 1),
              }}
            />
          );
        }

        return <FormattedMessage id="period.overtime.shootout" defaultMessage="Shoot-out" />;
      }

      return (
        <span className={"recovery-" + currentState.recovery}>
          <FormattedMessage
            id="period.full"
            defaultMessage="Period {period}"
            values={{
              period: periodId,
            }}
          />
        </span>
      );
    }
  };

  const renderClockTime = (displayTime) => {
    if (!displayTime) {
      return displayTime;
    }

    if (sport !== "handball") {
      return displayTime;
    }

    if (currentState?.period?.periodId === 2) {
      const [minutes, seconds] = displayTime?.split(":");
      return `${parseInt(minutes, 10) + fixtureProfile.periodLength}:${seconds}`;
    }

    return displayTime;
  };

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>
          <div className="period-display">{renderPeriodText()}</div>
          {currentState.period &&
            (currentState.period.periodStatus === "inProgress" ||
              (currentState.period && currentState.period.periodStatus === "ended")) && (
              <React.Fragment>
                <div className="edit-clock">
                  {!editClock && (
                    <i className="edit-start fas fa-pen-square" onClick={() => setEditClock(!editClock)}></i>
                  )}
                  {editClock && (
                    <i className="edit-done fas fa-check-square" onClick={() => setEditClock(!editClock)}></i>
                  )}
                </div>
              </React.Fragment>
            )}
          {editClock && (
            <React.Fragment>
              <ClockAdjust className="adjust-minutes" up="ArrowRight" down="ArrowLeft" />
              <ClockAdjust className="adjust-seconds" up="ArrowUp" down="ArrowDown" />
            </React.Fragment>
          )}
          <div className={"time-display recovery-" + currentState.recovery}>{renderClockTime(displayTime)}</div>
          {!panel?.options?.disableControls && (
            <ClockButtons
              clockRunning={clockRunning}
              setClockRunning={_setClockRunning}
              currentState={currentState}
              isEnabled={enableButtons}
              updateState={updateState}
              eventStore={eventStore}
              managePlays={managePlays}
              manageEvents={manageEvents}
            />
          )}
          {currentState.recovery && (
            <span className="recovery-label">
              <FormattedMessage id="recalculating" defaultMessage="Re-calculating" />
              <i className="fas fa-cog fa-spin"></i>
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Clock);
