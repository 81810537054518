// Shot Type Panel
import { TwoPtTypes, ThreePtTypes } from "../../subTypes/EliteShotTypes";
import { MadeShotOptions } from "../../Options/ShotOptions";

export var ShotTypePanel = {
  name: "shot-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.type",
  allowBack: { state: "primary", value: "shot-player", event: "main" },
  showOn: [{ state: "primary", value: "shot-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    // 2pt
    {
      name: "2pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "2pt",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: TwoPtTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [{ action: "copyEvent", value: "shot", type: "to" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            {
              action: "clearEvent",
              value: "block",
              type: "main",
            },
            { action: "saveData", value: false, type: "blocked" },
            { action: "copyEvent", value: "shot", type: "to" },
            {
              action: "nextState",
              value: "shot-rebound",
              type: "primary",
            },
          ],
          conditions: [{ item: "success", value: true, event: "main", operator: "not" }],
        },
      ],
    },
    // 3pt
    {
      name: "3pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "3pt",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ThreePtTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [{ action: "copyEvent", value: "shot", type: "to" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            {
              action: "clearEvent",
              value: "block",
              type: "main",
            },
            { action: "saveData", value: false, type: "blocked" },
            { action: "copyEvent", value: "shot", type: "to" },
            {
              action: "nextState",
              value: "shot-rebound",
              type: "primary",
            },
          ],
          conditions: [{ item: "success", value: true, event: "main", operator: "not" }],
        },
      ],
    },
    // Made Shot Options
    {
      name: "made-shot-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "options",
        children: MadeShotOptions,
        layout: "option-button",
      },
    },
    // Made Shot confirm
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button center action",
          mode: "enable",
          text: "ok",
          showOn: [
            {
              state: "event.subType",
              value: null,
              operator: "not",
              event: "main",
            },
          ],
          actionGroups: [
            {
              actions: [
                {
                  action: "saveData",
                  value: [{ option: "and1InPP", value: true }],
                  type: "options",
                },
                {
                  action: "setPossession",
                  value: { currentEntity: false },
                  type: "main",
                },
                { action: "stopClock", value: "stop", type: "main" },
              ],
              conditions: [
                { item: "fixture.isPowerPlayEnabled", operator: "is" },
                { item: "fixture.isPowerPlayActive", operator: "is" },
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
                {
                  item: "options",
                  value: "foulOnShot",
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [{ action: "copyEvent", value: "shot", type: "to" }],
            },
            {
              actions: [
                {
                  action: "setPossession",
                  value: { currentEntity: false },
                  type: "main",
                },
              ],
              conditions: [
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
                {
                  item: "options",
                  value: "foulOnShot",
                  event: "main",
                  operator: "not",
                },
              ],
            },
            {
              actions: [
                {
                  action: "setPossession",
                  value: { currentEntity: true },
                  type: "main",
                },
                { action: "stopClock", value: "stop", type: "main" },
              ],
              conditions: [
                { item: "fixture.isPowerPlayEnabled", operator: "not" },
                { item: "fixture.isPowerPlayActive", operator: "not" },
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
                {
                  item: "options",
                  value: "foulOnShot",
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {action: "deleteFromPlay", type: "foul", value: "foul"},
                {
                  action: "sendEvent",
                  value: "edit",
                  type: "main",
                },
                { action: "copyEvent", value: "shot", type: "to" },
                {
                  action: "clearData",
                  value: ["personId"],
                  type: "main",
                },
              ],
              conditions: [
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                { action: "newEvent", value: null, type: "main" },
                {
                  action: "saveData",
                  value: ["eventTime"],
                  type: "variables",
                },
                { action: "saveData", value: "foul", type: "eventType" },
                { action: "modifyEvent", value: "swapEntities", type: "main" },
                {
                  action: "clearData",
                  value: ["options", "subType", "scores", "success"],
                  type: "main",
                },
                {
                  action: "sendEvent",
                  value: "new",
                  type: "main",
                },
                { action: "copyEvent", value: "foul", type: "to" },
                { action: "copyEvent", value: "shot", type: "from" },
              ],
              conditions: [
                {
                  item: "options",
                  value: "foulOnShot",
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "nextState",
                  value: "shot-assist",
                  type: "primary",
                },
              ],
              conditions: [
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "nextState",
                  value: "shot-assist",
                  type: "primary",
                },
              ],
              conditions: [
                {
                  item: "success",
                  value: true,
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "sendEvent",
                  value: "new",
                  type: "main",
                },
                { action: "copyEvent", value: "shot", type: "to" },
                {
                  action: "nextState",
                  value: "shot-rebound",
                  type: "primary",
                },
              ],
              conditions: [
                {
                  item: "success",
                  value: false,
                  event: "main",
                  operator: "is",
                },
              ],
            },
          ],
        },
      ],
    },
    // Missed Shot Options
    // {
    //   name: "missed-shot-options",
    //   component: "OptionGroup",
    //   layout: "option-group",
    //   mode: "visible",
    //   showOn: [
    //     {
    //       state: "event.success",
    //       value: false,
    //       operator: "is",
    //       event: "main",
    //     },
    //   ],
    //   options: {
    //     value: "options",
    //     children: MissedShotOptions,
    //     layout: "option-button",
    //   },
    // },
    // Block Options
    {
      name: "block-panel",
      component: "SubPanel",
      layout: "sub-panel align-center",
      mode: "visible",
      text: null,
      showOn: [
        {
          state: "event.success",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      children: [
        // Block Button
        // {
        //   name: "block-button",
        //   component: "TypeButton",
        //   layout: "match-button  ",
        //   mode: "enable",
        //   text: "block",
        //   showOn: [
        //     {
        //       state: "event.subType",
        //       value: null,
        //       operator: "not",
        //       event: "main",
        //     },
        //   ],
        //   actionGroups: [
        //     {
        //       actions: [
        //         {
        //           action: "saveData",
        //           value: [{ option: "blocked", value: true }],
        //           type: "options",
        //         },
        //         { action: "sendEvent", value: "new", type: "main" },
        //         {
        //           action: "clearData",
        //           value: ["personId"],
        //           type: "main",
        //         },
        //         {
        //           action: "clearEvent",
        //           value: "block",
        //           type: "main",
        //         },
        //         {
        //           action: "nextState",
        //           value: "shot-block",
        //           type: "primary",
        //         },
        //       ],
        //       conditions: [],
        //     },
        //   ],
        // },
        // No Block Button
        // {
        //   name: "no-block",
        //   component: "TypeButton",
        //   layout: "match-button",
        //   mode: "enable",
        //   text: "block.none",
        //   showOn: [
        //     {
        //       state: "event.subType",
        //       value: null,
        //       operator: "not",
        //       event: "main",
        //     },
        //   ],
        //   actionGroups: [
        //     {
        //       actions: [
        //         {
        //           action: "sendEvent",
        //           value: "new",
        //           type: "main",
        //         },
        //         {
        //           action: "clearEvent",
        //           value: "block",
        //           type: "main",
        //         },
        //         {
        //           action: "clearData",
        //           value: ["personId"],
        //           type: "main",
        //         },
        //         {
        //           action: "nextState",
        //           value: "shot-rebound",
        //           type: "primary",
        //         },
        //       ],
        //       conditions: [],
        //     },
        //   ],
        //   startEvent: false,
        //   startPlay: false,
        // },
      ],
    },
  ],
};
