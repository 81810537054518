import React, { useContext, useRef, useMemo, useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Container } from "reactstrap";
import debounce from "lodash/debounce";

import { FixtureDetailsContext } from "../../Config/FixtureDetails";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import { uniqueBy } from "../Base/Utilities/CollectionUtilities";
import { sendAttendance, sendFixtureRole } from "../Base/Utilities/ManagePlayers";

import GameInfo from "./GameInfo";
import GameRules from "./GameRules";
import Officials from "./Officials";
import GameDetailsControls from "./GameDetailsControls";

import FreshChat from "../ChatWidget/FreshChat";

import "./GameDetails.scss";
import NewOfficials from "./NewOfficials";

const GameDetails = ({ match, history, title, currentState }) => {
  const { competitors, errorMsg, hasLoaded, forceRefresh, fixtureProfile, fixtureRoles, sport } =
    useContext(FixtureDetailsContext);
  const [officialsData, setOfficialsData] = useState([]);
  const pageRef = useRef();

  const fixtureEntity = currentState.mqtt?.entities.find((entity) => entity.entityId === "FIXTURE");

  const getAvailableOfficials = () => {
    let availableOfficials = [];

    if (fixtureRoles?.fixture?.length) {
      availableOfficials = uniqueBy(
        fixtureRoles.fixture.map((fixtureRole) => {
          return {
            personId: fixtureRole.personId,
            name: fixtureRole.nameFullLocal || fixtureRole.nameFullLatin,
          };
        }),
        "personId",
      );
    }

    if (fixtureRoles?.season?.length) {
      availableOfficials = [
        ...availableOfficials,
        ...uniqueBy(
          fixtureRoles.season.map((fixtureRole) => {
            return {
              personId: fixtureRole.personId,
              name: fixtureRole.nameFullLocal || fixtureRole.nameFullLatin,
            };
          }),
          "personId",
        ),
      ];
    }

    return availableOfficials;
  };

  const availableOfficials = getAvailableOfficials();

  useEffect(() => {
    const newOfficialsObj = {};
    if (fixtureRoles?.fixture) {
      fixtureRoles.fixture.forEach(
        (official) =>
          (newOfficialsObj[`${official.personId}_${official.role}`] = {
            role: official.role,
            personId: official.personId,
            number: official.roleNumber,
          }),
      );
    }

    if (fixtureEntity?.roles) {
      Object.entries(fixtureEntity.roles).forEach(([role, persons]) => {
        Object.keys(persons).forEach((personId) => {
          newOfficialsObj[`${personId}_${role}`] = {
            role: role,
            personId: personId,
            number: persons[personId].number,
          };
        });
      });
    }
    setOfficialsData(Object.values(newOfficialsObj));
  }, [fixtureRoles, fixtureEntity]);

  // eslint-disable-next-line
  const updateAttendance = useCallback(
    debounce(
      (newAttendance) =>
        sendAttendance(parseInt(newAttendance), currentState.mqtt, currentState.fixtureId, fixtureProfile),
      1500,
    ),
    [currentState.mqtt, currentState.fixtureId, fixtureProfile],
  );

  const getValidRoleNumberForPerson = (personData) => {
    const personsWithSameRole = officialsData.filter((o) => o.role === personData.role && o.personId !== null);
    const existingNumbers = personsWithSameRole.map((o) => o.number).sort((a, b) => a - b);
    const newPersonNumberGuess = existingNumbers.findIndex((o, index) => o !== index + 1);
    return newPersonNumberGuess !== -1 ? newPersonNumberGuess + 1 : personsWithSameRole.length + 1;
  };

  const updateOfficialsData = (newOfficialPersonData, officialIndex) => {
    // pushing a new entry at the end
    if (officialIndex === -1) {
      setOfficialsData([...officialsData, newOfficialPersonData]);
      return;
    }
    // removing an existing entry
    if (newOfficialPersonData === null) {
      const newOfficialsData = [...officialsData];
      const [removedOfficial] = newOfficialsData.splice(officialIndex, 1);
      setOfficialsData(newOfficialsData);
      if (removedOfficial.personId && removedOfficial.role) {
        const removedOfficialName = availableOfficials.find((o) => o.personId === removedOfficial.personId).name;
        removedOfficial.name = removedOfficialName;
        sendFixtureRole(removedOfficial, currentState.mqtt, match.params.fixtureId, "deleted");
      }
    } else {
      const oldOfficialPersonData = officialsData[officialIndex];
      const newOfficialsData = [...officialsData];
      newOfficialPersonData.number = getValidRoleNumberForPerson(newOfficialPersonData);
      newOfficialsData[officialIndex] = newOfficialPersonData;
      setOfficialsData(newOfficialsData);
      if (
        newOfficialPersonData.personId &&
        newOfficialPersonData.role &&
        (newOfficialPersonData.personId !== oldOfficialPersonData.personId ||
          newOfficialPersonData.role !== oldOfficialPersonData.role)
      ) {
        newOfficialPersonData.name = availableOfficials.find((o) => o.personId === newOfficialPersonData.personId).name;
        sendFixtureRole(newOfficialPersonData, currentState.mqtt, match.params.fixtureId, "added");
      }
    }
  };

  const titles = useMemo(() => {
    const participants = [];
    if (competitors) {
      if (competitors[0].isHome) {
        participants.push(competitors[0], competitors[1]);
      } else {
        participants.push(competitors[1], competitors[0]);
      }
    }
    const participantsTitle = participants
      .filter((team) => !!team.nameFullLocal)
      .map((it) => it.nameFullLocal)
      .join(" v. ");
    const mainTitleBase = "Game Details";
    const main = [participantsTitle, mainTitleBase].filter((e) => !!e).join(" - ");
    const page = `${main} - ${title}`;
    return { main, page };
  }, [title, competitors]);

  return (
    <Container className="setup-page">
      <PageDisplay
        title={titles.main}
        pageTitle={titles.page}
        ref={pageRef}
        error={errorMsg}
        loading={!hasLoaded}
        history={history}
        reload={forceRefresh}
      >
        {hasLoaded && (
          <>
            {sport === "handball" && <FreshChat />}
            <div className="game-details-container">
              <GameInfo updateAttendance={updateAttendance} />
              <GameRules />
              {sport === "hockey" ? (
                <NewOfficials currentState={currentState} />
              ) : (
                <Officials
                  officialsData={officialsData}
                  updateOfficialsData={updateOfficialsData}
                  availableOfficials={availableOfficials}
                />
              )}
            </div>
            <GameDetailsControls officialsData={officialsData} />
          </>
        )}
      </PageDisplay>
    </Container>
  );
};

export default injectIntl(withRouter(GameDetails));
